*{
    //padding: 0 !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.classCard{
    border: solid black 2px; 
    /*width: 25%; */
    padding: 1em;
    border-radius: 1em;
    /* margin: .1em !important;*/
    .deletebtn, .editbtn{
        height: 30px;
        opacity: .2;
        cursor: pointer;
        &:nth-child(1){
            margin-right: 10px;
        }
        &:hover{
            opacity: 1;
        }
    }
    

}

.App, #root{
    padding: 0;
    height: 100vh;
    // overflow-y: scroll;
}

body{
    background-color: beige;
    height: 100vh;
}

.deletebtn{
    height: 10px;
}
input{
    width: auto;
}
.red{
    background-color: rgba(255, 0, 0, 0.699);
}
.green{
    background-color: rgba(0, 128, 0, 0.575);
}
.blue{
    background-color: rgba(0, 17, 255, 0.699);
}
.yellow{
    background-color: rgba(255, 251, 0, 0.692);
}

.incorrect{
    border: red solid 1px;
}
.errorMsg{
    //margin-left: 1vw;
    display: flex;
    justify-content: left;
    width: 100%;
    text-align: right;
    color:  #ff0000;
    

}

.App {
    justify-content: stretch;
    align-items: stretch;
}

#classes {
    >div{
        min-height: 0;
    }
}

#class-holder {
    @media only screen and (min-width: 576px) {
        // height: 100%;
        // align-self: stretch;
    }
    // height: calc(100vh - calc(1.275rem - 72px));// (68vh);
    //height: 100%;
    //300px;
    flex: 1 1 1px;
    min-width: 0;

    overflow-y: scroll;
}

footer {
    // height: 6vh;
}

.drop-down{
    //background: rebeccapurple;
    //position: absolute;
    //z-index: 2;
    //border:  1px solid black;
    //border-top: 0px;
    //width: 100%;

    //box-shadow: 2px 2px;
    //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
    &:hover{
        background: skyblue;
    }
}

input::-webkit-calendar-picker-indicator {
    display: none;
    }

.hide{
    display: none;
}

input{
    border-radius: 5px;
}